import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-sidebar-item__children"
}

import { computed } from "vue";
import type { AppSidebarLink } from "@/shared/types/components";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarItem',
  props: {
    isChild: { type: Boolean, default: false },
    isParentActive: { type: Boolean, default: false },
    activeChildPath: { default: "" },
    item: {}
  },
  setup(__props: any) {



const linkProps = computed(() => {
  if (__props.item.isExternal) {
    return {
      href: __props.item.path,
      "link-component": "a",
    };
  }
  return {
    to: __props.item.path,
    "link-component": "router-link",
  };
});

return (_ctx: any,_cache: any) => {
  const _component_AppSidebarItem = _resolveComponent("AppSidebarItem", true)!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([
      'app-sidebar-item',
      {
        'app-sidebar-item--has-link': _ctx.item.path,
        'app-sidebar-item--child': _ctx.isChild,
        'app-sidebar-item--active': _ctx.isChild
          ? _ctx.item.path === _ctx.activeChildPath
          : _ctx.isParentActive,
      },
    ])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.path ? AppLink : 'div'), _mergeProps(_ctx.item.path ? linkProps.value : {}, {
      class: [
        'app-sidebar-item__link',
        {
          'app-sidebar-item__link--active': _ctx.isChild
            ? _ctx.item.path === _ctx.activeChildPath
            : _ctx.isParentActive,
          'app-sidebar-item__link--child': _ctx.isChild,
        },
      ]
    }), {
      default: _withCtx(() => [
        (_ctx.item.icon)
          ? (_openBlock(), _createBlock(BaseIcon, {
              key: 0,
              size: "28px",
              icon: _ctx.item.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass([
          'app-sidebar-item__link__name',
          { 'app-sidebar-item__link__name--child': _ctx.isChild },
        ])
        }, _toDisplayString(_ctx.item.name), 3)
      ]),
      _: 1
    }, 16, ["class"])),
    (_ctx.isParentActive && _ctx.item.children?.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_AppSidebarItem, {
              key: child.name,
              item: child,
              "is-child": true,
              "is-parent-active": _ctx.isParentActive,
              "active-child-path": _ctx.activeChildPath
            }, null, 8, ["item", "is-parent-active", "active-child-path"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-information" }

import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppInformation',
  props: {
    content: {},
    size: { default: "18px" },
    allowHtml: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppTooltip, {
      content: _ctx.content,
      "allow-html": _ctx.allowHtml,
      class: "app-information__tooltip"
    }, {
      default: _withCtx(() => [
        _createVNode(BaseIcon, {
          icon: "information",
          size: _ctx.size,
          class: "app-information__icon"
        }, null, 8, ["size"])
      ]),
      _: 1
    }, 8, ["content", "allow-html"])
  ]))
}
}

})
<template>
  <div class="app-layout">
    <AppExpiryBanner
      v-if="showSubscriptionBanner"
      @open-book-call-modal="handleOpenBookCallModal"
    />

    <AppHeader
      :class="{ 'app-layout__header-with-banner': showSubscriptionBanner }"
    />

    <main
      :class="{
        'app-layout__body': true,
        'app-layout__header-with-banner': showSubscriptionBanner,
      }"
    >
      <AppSidebar
        :links="navigationLinks"
        :class="{
          'app-layout__header-with-banner-top': showSubscriptionBanner,
        }"
      />
      <div class="app-layout__body__content" ref="appContent">
        <slot />
      </div>
    </main>
  </div>
  <BookCallModal v-model="bookCallModal" />
</template>

<script setup lang="ts">
import { ref, useTemplateRef, watch } from "vue";
import { useRoute } from "vue-router";
import AppHeader from "@/components/app/AppHeader/AppHeader.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";
import AppSidebar from "@/components/app/AppSidebar/AppSidebar.vue";
import { useNavigation } from "@/shared/composables/useNavigation/useNavigation";

const route = useRoute();
const webCustomerStore = useWebCustomerStore();
const { showSubscriptionBanner } = webCustomerStore;
const { navigationLinks } = useNavigation();

const appContent = useTemplateRef<HTMLDivElement>("appContent");
const bookCallModal = ref(false);

const handleOpenBookCallModal = () => {
  bookCallModal.value = true;
};

watch(
  () => route,
  () => {
    if (appContent.value) {
      appContent.value.scrollTop = 0;
    }
  },
);
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-layout {
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-grow: 1;
    padding-left: rem(78px);
    position: relative;

    &__content {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      background-color: $background;
      padding: rem(20px);
      padding-top: rem(65px);
      flex-grow: 1;
    }
  }

  &__header-with-banner {
    margin-top: rem(32px);

    &-top {
      top: rem(124px);
    }
  }

  &__navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16px) rem(48px);
    height: rem(64px);
    width: 100%;
    position: fixed;
    top: rem(64px);
    z-index: 11;
    background-color: $white;
    box-sizing: border-box;

    &__brand {
      display: flex;
      align-items: center;
    }

    &__logo {
      width: rem(100px);
      height: auto;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: rem(16px);

      &__upgrade {
        margin-right: rem(8px);
      }
    }
  }
}
</style>

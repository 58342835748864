<template>
  <li
    role="menuitem"
    :class="[
      'app-floating-menu-item',
      { 'app-floating-menu-item--selected': item.isSelected },
      { 'app-floating-menu-item--disabled': item.isDisabled },
    ]"
  >
    <AppLink v-if="item.link" v-bind="linkProp" :class="labelClasses">
      <BaseIcon
        :style="iconColor"
        v-if="item.iconSrc"
        :icon="item.iconSrc"
        size="24px"
        class="app-floating-menu-item__icon"
      />
      {{ item.label }}
    </AppLink>
    <span v-else :class="labelClasses">
      <BaseIcon
        v-if="item.iconSrc"
        size="22px"
        :icon="item.iconSrc"
        class="app-floating-menu-item__icon"
      />
      {{ item.label }}
    </span>
  </li>
</template>

<script setup lang="ts">
import type { AppFloatingMenuOption } from "@/shared/types/components";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import { computed } from "vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";

const { item } = defineProps<{ item: AppFloatingMenuOption }>();

const iconColor = computed(() => {
  if (item.iconColor) {
    return { color: item.iconColor };
  }
  return {};
});

const labelClasses = computed(() => [
  "app-floating-menu-item__label",
  { "app-floating-menu-item__label--selected": item.isSelected },
  { "app-floating-menu-item__label--disabled": item.isDisabled },
]);

const linkProp = computed(() => {
  if (item.isExternalLink) {
    return { href: item.link, "link-component": "a" };
  }
  return { to: item.link, "link-component": "router-link" };
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-floating-menu-item {
  cursor: pointer;
  box-sizing: border-box;
}

.app-floating-menu-item--disabled {
  cursor: default;
}

.app-floating-menu-item:hover:not(.app-floating-menu-item--selected):not(
    .app-floating-menu-item--disabled
  ) {
  background-color: $backgroundAlternative;
}

.app-floating-menu-item__icon {
  margin-right: rem(8px);
}

.app-floating-menu-item--selected {
  background-color: $backgroundAlternative;
}

.app-floating-menu-item__label {
  color: $black;
  font-weight: 400;
  display: block;
  line-height: rem(25px);
  padding: rem(12px) rem(18px);
  display: flex;
  align-items: center;
}

.app-floating-menu-item__label--selected {
  color: $primary;
}

.app-floating-menu-item__label--disabled {
  color: $disabledBackground;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-information-group" }
const _hoisted_2 = {
  class: "app-information-group__label",
  "data-testid": "appInformationGroupLabel"
}
const _hoisted_3 = {
  key: 0,
  class: "app-information-group__label__asterisk"
}
const _hoisted_4 = {
  key: 1,
  class: "app-information-group__label__note"
}

import AppInformation from "@/components/app/AppInformation/AppInformation.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppInformationGroup',
  props: {
    label: {},
    information: {},
    allowHtml: { type: Boolean },
    isRequired: { type: Boolean },
    isOneRequired: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass([
            'app-information-group__label__title',
            {
              'app-information-group__label__title--required': _ctx.isRequired,
            },
          ])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired || _ctx.isOneRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " * "))
            : _createCommentVNode("", true),
          (_ctx.isOneRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (at least one is required) "))
            : _createCommentVNode("", true)
        ], 2),
        _renderSlot(_ctx.$slots, "trailing"),
        (_ctx.information && _ctx.information.length > 0)
          ? (_openBlock(), _createBlock(AppInformation, {
              key: 0,
              content: _ctx.information,
              "data-testid": "appInformationGroupLabelInfo",
              "allow-html": _ctx.allowHtml
            }, null, 8, ["content", "allow-html"]))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "explanation")
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})
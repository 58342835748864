<template>
  <form @submit.prevent autocomplete="off">
    <AppInput
      icon="search"
      icon-size="24"
      placeholder="Search..."
      autocomplete="off"
      v-model.trim="model"
    />
  </form>
</template>

<script setup lang="ts">
import AppInput from "@/components/app/AppInput/AppInput.vue";

const model = defineModel<string>();
</script>

<style lang="scss" scoped>
@import "@/styles/functions.scss";

.app-input {
  width: rem(400px);
  border-radius: rem(16px) !important;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, useTemplateRef, watch, onMounted } from "vue";
import tippy, { Instance, hideAll, Props } from "tippy.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTooltip',
  props: {
    content: { default: "" },
    allowHtml: { type: Boolean, default: false },
    hideOnClick: { type: Boolean, default: true },
    showIf: { type: Boolean, default: true },
    arrow: { type: Boolean, default: true },
    appendTo: { default: () => (document.body) },
    maxWidth: { default: 350 },
    offsetX: { default: 0 },
    offsetY: { default: 5 },
    delay: { default: 0 }
  },
  setup(__props: any) {



const appTooltip = useTemplateRef<HTMLDivElement>("appTooltip");

const instance = ref<Instance<Props>>();

const createTippyInstance = (content: string) => {
  return tippy(appTooltip.value!, {
    animation: "shift-away-subtle",
    hideOnClick: __props.hideOnClick,
    theme: "app-tooltip-light",
    allowHTML: __props.allowHtml,
    appendTo: __props.appendTo,
    interactiveBorder: 4,
    interactive: true,
    arrow: __props.arrow,
    content,
    offset: [__props.offsetX, __props.offsetY],
    maxWidth: __props.maxWidth,
    delay: __props.delay,
    onShow(instance) {
      hideAll({ exclude: instance });
    },
  });
};

watch(
  () => __props.content,
  (newContent: string) => {
    if (instance.value) {
      instance.value.destroy();

      instance.value = createTippyInstance(newContent);
    }
  },
);

onMounted(() => {
  if (__props.showIf && __props.content.length) {
    instance.value = createTippyInstance(__props.content);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "app-tooltip",
    ref_key: "appTooltip",
    ref: appTooltip
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 512))
}
}

})
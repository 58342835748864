<template>
  <button
    @click="isActive = !isActive"
    :aria-controls="isActive ? 'user-menu' : undefined"
    :aria-expanded="isActive"
    aria-haspopup="menu"
    :class="['user-avatar', { 'user-avatar--active': isActive }]"
  >
    {{ initials }}
  </button>
</template>

<script setup lang="ts">
defineProps<{
  initials: string;
}>();

const isActive = defineModel<boolean>();
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.user-avatar {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(38px);
  box-sizing: border-box;
  font-size: rem(14px);
  letter-spacing: rem(1px);
  color: $black;
  background-color: $lightGray;
  border-radius: 100%;
  aspect-ratio: 1/1;

  &--active,
  &:hover {
    color: $white;
    background-color: $primary;
  }
}
</style>

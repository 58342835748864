<template>
  <AppModal v-model="model" size="small">
    <template #header>
      <h3 class="modal-title">Add organisation to the Net0 Platform</h3>
    </template>

    <template #body>
      <div class="add-organization-modal__body">
        <AppInformationGroup
          label="Company Name"
          is-required
          class="add-organization-modal__section"
        >
          <AppInput v-model="company.name" placeholder="Organisation name" />
        </AppInformationGroup>

        <AppInformationGroup
          label="Company web presence"
          allow-html
          is-one-required
        >
          <div class="add-organization-modal__web-presence">
            <label> Organisation website </label>
            <div class="add-organization-modal__web-presence__input">
              <AppInput
                v-model.trim="company.website"
                placeholder="https://www.example.com"
              />
              <AppErrorMessage :show="hasWebsiteError" v-if="hasWebsiteError">
                Please enter a valid URL
              </AppErrorMessage>
            </div>

            <label> Organisation LinkedIn URL </label>
            <div class="add-organization-modal__web-presence__input">
              <AppInput
                v-model.trim="company.linkedInURL"
                placeholder="https://www.linkedin.com/company/example"
              />
              <AppErrorMessage :show="hasLinkedInError" v-if="hasLinkedInError">
                Please enter a valid LinkedIn URL
              </AppErrorMessage>
            </div>

            <label>
              Other web presence
              <AppInformation
                content="If the organisation lacks a website or LinkedIn page, please
                  provide any link that offers additional information like
                  Facebook, Twitter page, a news article, or a pitch deck."
              />
            </label>
            <div class="add-organization-modal__web-presence__input">
              <AppInput
                v-model.trim="company.webPresence"
                placeholder="Social media or other relevant links"
              />
              <AppErrorMessage
                :show="hasWebPresenceError"
                v-if="hasWebPresenceError"
              >
                Please enter a valid URL
              </AppErrorMessage>
            </div>
          </div>
        </AppInformationGroup>
      </div>
    </template>

    <template #footer="{ hideModal }">
      <div class="add-organization-modal__footer">
        <AppButton
          label="Cancel"
          type="empty"
          data-testid="cancel-button"
          @click="hideModal"
          :is-disabled="isLoading"
          class="add-organization-modal__button"
        />
        <AppButton
          label="Add organisation"
          data-testid="add-button"
          :is-loading="isLoading"
          :is-disabled="isAddButtonDisabled"
          @click="handleAddClick"
          class="add-organization-modal__button add-organization-modal__button--primary"
        />
      </div>
    </template>
  </AppModal>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppInformationGroup from "@/components/app/AppInformationGroup/AppInformationGroup.vue";
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import {
  isEmpty,
  isValidLinkedinUrl,
  isValidUrl,
} from "@/shared/helpers/validators/validators";
import { NewCompanyModel } from "@/shared/types/organization";
import AppErrorMessage from "@/components/app/AppErrorMessage/AppErrorMessage.vue";

const props = defineProps<{
  name: string;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: "add", company: NewCompanyModel): void;
  (e: "cancel"): void;
}>();

const model = defineModel<boolean>({ required: true });

const company = ref({
  name: "",
  website: "",
  webPresence: "",
  linkedInURL: "",
});

const hasWebsiteError = computed(
  () => !isEmpty(company.value.website) && !isValidUrl(company.value.website),
);

const hasLinkedInError = computed(
  () =>
    !isEmpty(company.value.linkedInURL) &&
    !isValidLinkedinUrl(company.value.linkedInURL),
);

const hasWebPresenceError = computed(
  () =>
    !isEmpty(company.value.webPresence) &&
    !isValidUrl(company.value.webPresence),
);

const isAddButtonDisabled = computed(() => {
  const { name, website, linkedInURL, webPresence } = company.value;

  const areAllInputsEmpty =
    isEmpty(website) && isEmpty(linkedInURL) && isEmpty(webPresence);

  const hasValidationErrors =
    hasWebsiteError.value ||
    hasLinkedInError.value ||
    hasWebPresenceError.value;

  return isEmpty(name) || areAllInputsEmpty || hasValidationErrors;
});

watch(model, () => {
  company.value = {
    name: props.name,
    website: "",
    webPresence: "",
    linkedInURL: "",
  };
});

const handleAddClick = () => {
  emit("add", company.value);
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.add-organization-modal {
  &__body {
    display: flex;
    flex-direction: column;
    margin-top: rem(16px);
  }

  &__section:not(:last-child) {
    margin-bottom: rem(24px);
  }

  &__web-presence {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: rem(16px);
  }

  &__button {
    min-width: rem(120px);
  }
}
</style>

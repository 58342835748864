import { computed } from "vue";
import { useRoute } from "vue-router";
import type { AppFloatingMenuOption } from "@/shared/types/components";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";

export const useNavigationMenu = () => {
  const route = useRoute();
  const { isUserAdmin } = useWebCustomerStore();

  const menuOptions = computed<AppFloatingMenuOption[]>(() => {
    const baseOptions: AppFloatingMenuOption[] = [
      {
        name: "account",
        label: "Settings",
        iconSrc: "settings",
        link: "/user/profile",
        isSelected: route.name === "Profile",
      },
    ];

    const adminOption: AppFloatingMenuOption[] = isUserAdmin
      ? [
          {
            label: "Admin panel",
            link: "/admin-panel",
            iconSrc: "admin",
            name: "admin-panel",
            isSelected: route.name === "AdminPanel",
          },
        ]
      : [
          {
            name: "extendSubscription",
            label: "Extend subscription",
            iconSrc: "positive-check",
            iconColor: "#2795e3",
            link: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
          },
        ];

    const logoutOption: AppFloatingMenuOption[] = [
      {
        name: "logOut",
        label: "Log out",
        iconSrc: "logout",
        link: "/logout",
      },
    ];

    return [...adminOption, ...baseOptions, ...logoutOption];
  });

  return {
    menuOptions,
  };
};

<template>
  <li
    :class="[
      'app-sidebar-item',
      {
        'app-sidebar-item--has-link': item.path,
        'app-sidebar-item--child': isChild,
        'app-sidebar-item--active': isChild
          ? item.path === activeChildPath
          : isParentActive,
      },
    ]"
  >
    <component
      :is="item.path ? AppLink : 'div'"
      v-bind="item.path ? linkProps : {}"
      :class="[
        'app-sidebar-item__link',
        {
          'app-sidebar-item__link--active': isChild
            ? item.path === activeChildPath
            : isParentActive,
          'app-sidebar-item__link--child': isChild,
        },
      ]"
    >
      <BaseIcon v-if="item.icon" size="28px" :icon="item.icon" />

      <span
        :class="[
          'app-sidebar-item__link__name',
          { 'app-sidebar-item__link__name--child': isChild },
        ]"
      >
        {{ item.name }}
      </span>
    </component>

    <ul
      class="app-sidebar-item__children"
      v-if="isParentActive && item.children?.length"
    >
      <AppSidebarItem
        v-for="child in item.children"
        :key="child.name"
        :item="child"
        :is-child="true"
        :is-parent-active="isParentActive"
        :active-child-path="activeChildPath"
      />
    </ul>
  </li>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { AppSidebarLink } from "@/shared/types/components";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";

const {
  isChild = false,
  isParentActive = false,
  activeChildPath = "",
  item,
} = defineProps<{
  isChild?: boolean;
  isParentActive?: boolean;
  activeChildPath?: string;
  item: AppSidebarLink;
}>();

const linkProps = computed(() => {
  if (item.isExternal) {
    return {
      href: item.path,
      "link-component": "a",
    };
  }
  return {
    to: item.path,
    "link-component": "router-link",
  };
});
</script>

<style lang="scss" scoped>
@import "src/styles/functions";
@import "src/styles/colors";

.app-sidebar-item {
  padding: rem(12px);
  color: $black;

  &__link {
    display: flex;
    align-items: center;
    color: $black;

    &--active {
      color: $primary;
    }

    &--child {
      padding-left: rem(8px);
    }

    &__name {
      margin-left: rem(16px);
      display: none;

      &--child {
        margin-left: 0;
      }

      .app-sidebar:hover & {
        display: block;
      }
    }
  }

  &--active &__link__name:not(.app-sidebar-item__link__name--child) {
    color: $primary;
    font-weight: 600;
  }

  &__link.router-link-exact-active &__link__name--child {
    color: $primary;
  }

  &--active,
  &--has-link:hover {
    color: $primary;
  }

  &--has-link:hover {
    background-color: $backgroundAlternative;
  }

  &--child {
    padding: rem(4px) rem(4px) rem(4px) rem(32px);
  }

  &--child:hover,
  &--child.app-sidebar-item--active {
    background-color: $background;
  }

  &__children {
    margin-top: rem(10px);
    list-style: none;
    padding: 0;
    display: none;
    flex-direction: column;
    gap: rem(4px);

    .app-sidebar:hover & {
      display: flex;
    }
  }
}
</style>

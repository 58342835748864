import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'AppErrorMessage',
  props: {
    show: { type: Boolean },
    fontSize: { default: "12px" }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "6a1cc09c": (_ctx.fontSize)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      'app-error-message',
      {
        'app-error-message--visible': _ctx.show,
      },
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})
<template>
  <AppModal class="app-free-trial-modal" v-model="isOpen" size="small">
    <template #header>
      <h3 class="modal-title">{{ title }}</h3>
    </template>
    <template #body>
      <p class="app-free-trial-modal__message">
        {{ message }}
      </p>
    </template>
    <template #footer="{ hideModal }">
      <AppButton label="Close" type="empty" @click="hideModal" />
      <AppButton
        href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
        label="Upgrade now"
      />
    </template>
  </AppModal>
</template>

<script setup lang="ts">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";

defineProps<{
  title: string;
  message: string;
}>();

const isOpen = defineModel<boolean>({ required: true });
</script>

import {
  Range,
  StartupFilter,
  FundingRoundFilter,
  InvestorFilter,
} from "@/shared/types/filters";
import { WCColumnMapping } from "@/shared/types/common";
import Vue from "vue";
import { RouteLocationRaw } from "vue-router";

export type AppButtonType = "primary" | "danger" | "empty" | "bordered";

export type AppModalSize =
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "fullscreen";

export type AppTextAreaResizeType = "both" | "horizontal" | "vertical" | "none";

export type AppFloatingMenuSide = "right" | "left";

export type AppTabsType = "default" | "material";

export interface AppTabsMap {
  name: string;
  title: string;
  element: Element;
}

export interface AppSelectGroupOption {
  name: string;
  label: string;
  group?: string;
  isSelected?: boolean;
}

export interface AppSelectGroup {
  name: string;
  placeholder: string;
  isSearchable?: boolean;
  options: AppSelectGroupOption[];
}

export interface FilterSelectGroup {
  name:
    | keyof StartupFilter
    | "sectors"
    | "challenges"
    | "similarClientId"
    | "searchableLocations"
    | keyof FundingRoundFilter
    | keyof InvestorFilter;

  title: string;
  maxResults?: number;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  isSingle?: boolean;
}

export interface FilterSelectOption {
  id: string | number | boolean;
  label: string;
  group: FilterSelectGroup["name"];
  include?: boolean;
  range?: Range<string | number>;
  [key: string]: unknown;
}

export interface AppSelectSearchTerm {
  term: string;
}

export interface AppSearchbarOption {
  id?: string | number;
  label?: string;
  [key: string]: unknown;
}

export interface AppFloatingMenuOption {
  iconSrc?: string;
  iconColor?: string;
  name: string;
  label: string;
  link?: string;
  isExternalLink?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  handler?: () => unknown;
}

export interface AppTableColumn {
  name: string;
  label: string;
  fixed?: boolean;
  sortable?: boolean;
  isVisible?: boolean;
  custom?: boolean;
  type?: CustomColumnType;
  isCenter?: boolean;
  width?: string;
  minWidth?: string;
}

export interface AppTableRow {
  id: number | string;
  [key: string]: unknown;
}

export type CustomColumnType = "FREETEXT" | "CHECKBOX";

export interface CustomColumnTypes {
  id: number;
  type: CustomColumnType;
}

export interface CustomColumnModel {
  columnType: CustomColumnTypes;
  id?: number;
  name: string;
}

export interface EditCustomColumnModel {
  clientId: number;
  customColumnId: number;
  value: string;
}

export interface CustomColumnPostResponse {
  editedDate: string;
  id: number;
  name: string;
  webCustomerId: number;
  columnType: CustomColumnTypes;
}

export interface CustomColumn {
  name: string;
  type: CustomColumnType;
}

export type CustomColumnsMap = Record<number, Record<number, WCColumnMapping>>;

export type CustomColumnResponse = Record<number, CustomColumn>;

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
  INACTIVE = "INACTIVE",
}

export interface Sort {
  column: string;
  direction: SortDirection;
}

export interface AppTablePaginationButton {
  page?: number;
  active: boolean;
  ellipsis: boolean;
}

export interface AppSidebarLinkChild {
  name: string;
  path: string;
}

export interface AppSidebarLink {
  icon?: string;
  name: string;
  path?: string;
  isExternal?: boolean;
  children?: Array<AppSidebarLinkChild>;
}

export interface AppChartSeries {
  name: string;
  data: number[];
}

export interface AppTelInputData {
  phone: string;
  activeCountryCode?: string;
  open: boolean;
  finishMounted: boolean;
  valid: boolean;
  selectedIndex: number | null;
  typeToFindInput: string;
  typeToFindTimer?: ReturnType<typeof setTimeout>;
  dropdownOpenDirection: "above" | "below";
  parsedPlaceholder: string;
  searchQuery: string;
}

export interface AdvancedFilterSection {
  name: string;
  title: string;
  groups?: FilterSelectOption["group"][];
}

export type AppToastType = "information" | "warning" | "danger" | "success";

export enum AffinitySalesForceError {
  API_KEY = "API_KEY",
  NO_FILTERS = "NO_FILTERS",
  SYNCING = "SYNCING",
}

export enum ContainerType {
  DEFAULT = "default",
  FULL_PAGE = "full-page",
}

export enum FilterOperatorType {
  AND = "AND",
  OR = "OR",
  NOT_IN = "NOT IN",
}

export enum AppLoaderSize {
  SM = "20px",
  MD = "40px",
  LG = "60px",
}

export enum AppLoaderPosition {
  DEFAULT = "relative",
  ABSOLUTE = "absolute",
  FIXED = "fixed",
}

export interface DraggedContext<T> {
  index: number;
  futureIndex: number;
  element: T;
}

export interface DropContext<T> {
  index: number;
  component: typeof Vue;
  element: T;
}

export interface Rectangle {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
}

export interface DraggableMoveEvent<T> {
  originalEvent: DragEvent;
  dragged: Element;
  draggedContext: DraggedContext<T>;
  draggedRect: Rectangle;
  related: Element;
  relatedContext: DropContext<T>;
  relatedRect: Rectangle;
  from: Element;
  to: Element;
  willInsertAfter: boolean;
  isTrusted: boolean;
}

export interface ApexAxisChartSeries<T> {
  name?: string;
  data: T[];
}

export interface ApexTooltipCustomFunctionParams<
  T = ApexAxisChartSeries<number>,
> {
  series: T[];
  seriesIndex: number;
  dataPointIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  w: any; //TODO: I left any here to avoid using :any type inline in a component, later if we have a extra free time we will explore how to complete this
}

export interface BreadcrumbItem {
  label: string;
  to?: RouteLocationRaw;
  handler?: (item: BreadcrumbItem, index?: number) => void;
  id?: number;
}

import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import type { AppSidebarLink } from "@/shared/types/components";

export function useActiveSidebarLinks(links: AppSidebarLink[]) {
  const router = useRouter();
  const route = useRoute();

  const searchPageLinks = [
    "/organizations/saved-search/",
    "/funding-rounds/saved-search/",
    "/user/savedSearch/organizations/",
    "/user/savedSearch/funding-rounds/",
    "/lists/",
    "/investors/",
    "/similar-companies",
  ];

  const getPortfolioPageLinks = () =>
    router
      .getRoutes()
      .flatMap((route) =>
        ["Portfolios", "Portfolio"].includes(route.name as string)
          ? [route.path]
          : [],
      );

  const activeLinks = computed(() => {
    const portfolioPageLinks = getPortfolioPageLinks();
    let parentPath = "";
    let childPath = "";

    if (portfolioPageLinks.includes(route.path)) {
      parentPath = "/lists";
    } else if (route.name === "AdminPanel") {
      parentPath = "/admin-panel";
    } else if (searchPageLinks.includes(route.path)) {
      parentPath = "/search";
    } else {
      const activeParent = links.find((item) =>
        route.path.includes(item.path as string),
      );
      if (activeParent) {
        parentPath = activeParent.path as string;

        if (activeParent.children?.length) {
          const activeChild = activeParent.children.find(
            (child) => route.path === child.path,
          );
          if (activeChild) {
            childPath = activeChild.path as string;
          }
        }
      }
    }

    return {
      parentPath,
      childPath,
    };
  });

  return {
    activeLinks,
  };
}

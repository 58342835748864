import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "add-organization-modal__body" }
const _hoisted_2 = { class: "add-organization-modal__web-presence" }
const _hoisted_3 = { class: "add-organization-modal__web-presence__input" }
const _hoisted_4 = { class: "add-organization-modal__web-presence__input" }
const _hoisted_5 = { class: "add-organization-modal__web-presence__input" }
const _hoisted_6 = { class: "add-organization-modal__footer" }

import { ref, computed, watch } from "vue";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppInformationGroup from "@/components/app/AppInformationGroup/AppInformationGroup.vue";
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import {
  isEmpty,
  isValidLinkedinUrl,
  isValidUrl,
} from "@/shared/helpers/validators/validators";
import { NewCompanyModel } from "@/shared/types/organization";
import AppErrorMessage from "@/components/app/AppErrorMessage/AppErrorMessage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppNewCompanyModal',
  props: /*@__PURE__*/_mergeModels({
    name: {},
    isLoading: { type: Boolean }
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["add", "cancel"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = _useModel<boolean>(__props, "modelValue");

const company = ref({
  name: "",
  website: "",
  webPresence: "",
  linkedInURL: "",
});

const hasWebsiteError = computed(
  () => !isEmpty(company.value.website) && !isValidUrl(company.value.website),
);

const hasLinkedInError = computed(
  () =>
    !isEmpty(company.value.linkedInURL) &&
    !isValidLinkedinUrl(company.value.linkedInURL),
);

const hasWebPresenceError = computed(
  () =>
    !isEmpty(company.value.webPresence) &&
    !isValidUrl(company.value.webPresence),
);

const isAddButtonDisabled = computed(() => {
  const { name, website, linkedInURL, webPresence } = company.value;

  const areAllInputsEmpty =
    isEmpty(website) && isEmpty(linkedInURL) && isEmpty(webPresence);

  const hasValidationErrors =
    hasWebsiteError.value ||
    hasLinkedInError.value ||
    hasWebPresenceError.value;

  return isEmpty(name) || areAllInputsEmpty || hasValidationErrors;
});

watch(model, () => {
  company.value = {
    name: props.name,
    website: "",
    webPresence: "",
    linkedInURL: "",
  };
});

const handleAddClick = () => {
  emit("add", company.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model).value = $event)),
    size: "small"
  }, {
    header: _withCtx(() => _cache[5] || (_cache[5] = [
      _createElementVNode("h3", { class: "modal-title" }, "Add organisation to the Net0 Platform", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(AppInformationGroup, {
          label: "Company Name",
          "is-required": "",
          class: "add-organization-modal__section"
        }, {
          default: _withCtx(() => [
            _createVNode(AppInput, {
              modelValue: company.value.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((company.value.name) = $event)),
              placeholder: "Organisation name"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(AppInformationGroup, {
          label: "Company web presence",
          "allow-html": "",
          "is-one-required": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[10] || (_cache[10] = _createElementVNode("label", null, " Organisation website ", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(AppInput, {
                  modelValue: company.value.website,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((company.value.website) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: "https://www.example.com"
                }, null, 8, ["modelValue"]),
                (hasWebsiteError.value)
                  ? (_openBlock(), _createBlock(AppErrorMessage, {
                      key: 0,
                      show: hasWebsiteError.value
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Please enter a valid URL ")
                      ])),
                      _: 1
                    }, 8, ["show"]))
                  : _createCommentVNode("", true)
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("label", null, " Organisation LinkedIn URL ", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(AppInput, {
                  modelValue: company.value.linkedInURL,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((company.value.linkedInURL) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: "https://www.linkedin.com/company/example"
                }, null, 8, ["modelValue"]),
                (hasLinkedInError.value)
                  ? (_openBlock(), _createBlock(AppErrorMessage, {
                      key: 0,
                      show: hasLinkedInError.value
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Please enter a valid LinkedIn URL ")
                      ])),
                      _: 1
                    }, 8, ["show"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("label", null, [
                _cache[8] || (_cache[8] = _createTextVNode(" Other web presence ")),
                _createVNode(AppInformation, { content: "If the organisation lacks a website or LinkedIn page, please\n                  provide any link that offers additional information like\n                  Facebook, Twitter page, a news article, or a pitch deck." })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(AppInput, {
                  modelValue: company.value.webPresence,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((company.value.webPresence) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: "Social media or other relevant links"
                }, null, 8, ["modelValue"]),
                (hasWebPresenceError.value)
                  ? (_openBlock(), _createBlock(AppErrorMessage, {
                      key: 0,
                      show: hasWebPresenceError.value
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" Please enter a valid URL ")
                      ])),
                      _: 1
                    }, 8, ["show"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(({ hideModal }) => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(AppButton, {
          label: "Cancel",
          type: "empty",
          "data-testid": "cancel-button",
          onClick: hideModal,
          "is-disabled": _ctx.isLoading,
          class: "add-organization-modal__button"
        }, null, 8, ["onClick", "is-disabled"]),
        _createVNode(AppButton, {
          label: "Add organisation",
          "data-testid": "add-button",
          "is-loading": _ctx.isLoading,
          "is-disabled": isAddButtonDisabled.value,
          onClick: handleAddClick,
          class: "add-organization-modal__button add-organization-modal__button--primary"
        }, null, 8, ["is-loading", "is-disabled"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
<template>
  <AppExpiryBanner
    v-if="showSubscriptionBanner"
    @open-book-call-modal="handleOpenBookCallModal"
  />

  <BookCallModal v-model="bookCallModal" />

  <header
    :class="[
      'claim-profile__header',
      { 'claim-profile__header--banner': showSubscriptionBanner },
    ]"
  >
    <AppLink
      link-component="router-link"
      to="/dashboard"
      class="claim-profile__header__brand"
    >
      <img
        :src="netZeroInsightsLogoHighRes"
        alt="Net Zero Insights"
        class="claim-profile__header__brand__logo"
      />
    </AppLink>
    <a @click="showModal = !showModal" class="claim-profile__header__link">
      Why should I submit my info?
    </a>
  </header>

  <div class="claim-profile__body">
    <router-view />
  </div>

  <SubmitInfoModal v-model="showModal" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { netZeroInsightsLogoHighRes } from "@/shared/constants/icons";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import SubmitInfoModal from "@/components/profileClaim/SubmitInfoModal/SubmitInfoModal.vue";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

const { showSubscriptionBanner } = useWebCustomerStore();
const route = useRoute();

const showModal = ref(false);
const bookCallModal = ref(false);

const getBodyPadding = computed(() => {
  if (route.name === "ClaimProfileForm") {
    return "48px 48px 0";
  }
  return "48px";
});

const handleOpenBookCallModal = () => {
  bookCallModal.value = true;
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.claim-profile {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16px) rem(48px);
    background-color: $white;
    box-sizing: border-box;
    z-index: 2;
    border-bottom: rem(0.5px) solid $border;

    &--banner {
      margin-top: rem(32px);
    }

    &__brand {
      display: flex;
      align-items: center;

      &__logo {
        width: rem(100px);
      }
    }

    &__link {
      font-size: rem(16px);
      font-weight: 600;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__body {
    padding: v-bind(getBodyPadding);
  }
}
</style>

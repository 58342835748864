import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import AppInput from "@/components/app/AppInput/AppInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchForm',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const model = _useModel<string>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    autocomplete: "off"
  }, [
    _createVNode(AppInput, {
      icon: "search",
      "icon-size": "24",
      placeholder: "Search...",
      autocomplete: "off",
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      modelModifiers: { trim: true }
    }, null, 8, ["modelValue"])
  ], 32))
}
}

})
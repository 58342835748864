<template>
  <div class="action-buttons">
    <AppButton
      type="empty"
      icon="positive-check"
      icon-size="28"
      icon-color="#2795e3"
      href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
      label="Upgrade"
      class="action-buttons__button"
      v-if="webCustomer.profileClaimToken"
    />
    <AppButton
      label="Edit company"
      type="empty"
      icon="edit"
      icon-size="28"
      :to="claimProfileUrl"
      class="action-buttons__button"
      v-if="webCustomer.preview && webCustomer.profileClaimToken"
    />
    <AppButton
      type="empty"
      label="Add Company"
      icon="add"
      icon-size="28"
      @click="showAddCompanyModal = true"
      class="action-buttons__button"
    />
  </div>
</template>

<script setup lang="ts">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import type { WebCustomer } from "@/shared/types/common";

defineProps<{
  webCustomer: WebCustomer;
  claimProfileUrl: string;
}>();

const showAddCompanyModal = defineModel("showAddCompanyModal");
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.action-buttons {
  display: flex;
  align-items: center;
  gap: rem(6px);
  padding-right: rem(8px);

  &__button {
    padding: rem(10px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    color: black;

    &:hover {
      background-color: $backgroundAlternative;
    }
  }
}
</style>

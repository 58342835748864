import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "action-buttons" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import type { WebCustomer } from "@/shared/types/common";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionButtons',
  props: /*@__PURE__*/_mergeModels({
    webCustomer: {},
    claimProfileUrl: {}
  }, {
    "showAddCompanyModal": {},
    "showAddCompanyModalModifiers": {},
  }),
  emits: ["update:showAddCompanyModal"],
  setup(__props: any) {



const showAddCompanyModal = _useModel(__props, "showAddCompanyModal");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.webCustomer.profileClaimToken)
      ? (_openBlock(), _createBlock(AppButton, {
          key: 0,
          type: "empty",
          icon: "positive-check",
          "icon-size": "28",
          "icon-color": "#2795e3",
          href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
          label: "Upgrade",
          class: "action-buttons__button"
        }))
      : _createCommentVNode("", true),
    (_ctx.webCustomer.preview && _ctx.webCustomer.profileClaimToken)
      ? (_openBlock(), _createBlock(AppButton, {
          key: 1,
          label: "Edit company",
          type: "empty",
          icon: "edit",
          "icon-size": "28",
          to: _ctx.claimProfileUrl,
          class: "action-buttons__button"
        }, null, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(AppButton, {
      type: "empty",
      label: "Add Company",
      icon: "add",
      "icon-size": "28",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showAddCompanyModal.value = true)),
      class: "action-buttons__button"
    })
  ]))
}
}

})
import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { AppLoaderSize, AppLoaderPosition } from "@/shared/types/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLoader',
  props: {
    size: { default: () => (AppLoaderSize.MD) },
    position: { default: () => (AppLoaderPosition.DEFAULT) }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "0d938e80": (_ctx.position),
  "4885ce50": (_ctx.size)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'app-loader',
      { 'app-loader--centred': _ctx.position !== _unref(AppLoaderPosition).DEFAULT },
    ])
  }, null, 2))
}
}

})
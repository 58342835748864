<template>
  <component
    :is="buttonComponent.component"
    :disabled="isDisabled || isLoading"
    :type="nativeType"
    :class="[
      'app-button',
      `app-button--${checkIsLoadingTextBtn || isDisabled ? 'disabled' : type}`,
      { 'app-button--icon-only': isIconOnly },
    ]"
    v-bind="{ ...$attrs, ...buttonComponent.bind }"
    :style="{ color }"
    @mouseover="setIsHover(true)"
    @mouseout="setIsHover(false)"
  >
    <slot name="left-icon" />
    <slot>
      <template v-if="icon">
        <AppLoader v-if="isLoading && icon" :size="AppLoaderSize.SM" />
        <AppIcon
          v-else-if="isIconImported(icon)"
          :src="iconSrc"
          :size="iconSize"
          :class="['app-button__icon', iconClass]"
        />
        <BaseIcon
          v-else
          :icon="icon"
          :class="['app-button__icon', iconClass]"
          :size="iconSize"
          :style="iconColorStyle"
        />
      </template>
      <span v-if="buttonLabel" class="app-button__label" :class="labelClass">
        {{ buttonLabel }}
      </span>
      <template v-if="trailingIcon">
        <AppIcon
          v-if="isIconImported(trailingIcon)"
          :src="trailingIconSrc"
          :size="trailingIconSize"
          :class="[
            'app-button__icon',
            iconClass,
            {
              'app-button__trailing-icon':
                shiftTrailingIconOnHover && isHovered,
            },
          ]"
        />
        <BaseIcon
          v-else
          :icon="trailingIcon"
          :class="['app-button__icon', iconClass]"
          :size="trailingIconSize"
        />
      </template>
    </slot>
  </component>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import AppLoader from "@/components/app/AppLoader/AppLoader.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import { AppButtonType } from "@/shared/types/components";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";
import { AppLoaderSize } from "@/shared/types/components";

const { getNewTabEnabled } = useWebCustomerStore();

const props = withDefaults(
  defineProps<{
    iconSize?: string;
    trailingIconSize?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    width?: string;
    height?: string;
    icon?: string;
    iconColor?: string;
    label?: string;
    color?: string;
    iconHover?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    type?: AppButtonType;
    nativeType?: "button" | "reset" | "submit";
    trailingIcon?: string;
    trailingIconHover?: string;
    shiftTrailingIconOnHover?: boolean;
    to?: string;
    href?: string;
    targetBlank?: boolean;
    labelClass?: string | Array<string> | Record<string, boolean>;
    iconClass?: string | Array<string> | Record<string, boolean>;
    loadingText?: string;
  }>(),
  {
    iconSize: "16px",
    trailingIconSize: "16px",
    padding: "8px 20px",
    fontWeight: "300",
    fontSize: "14px",
    type: "primary",
    nativeType: "button",
    width: "fit-content",
    loadingText: "Loading...",
  },
);

const isHovered = ref(false);

const isIconOnly = props.icon && !props.label && !props.trailingIcon;

const iconColorStyle = { color: props.iconColor };

const checkIsLoadingTextBtn = computed(() => props.isLoading && !props.icon);

const buttonLabel = computed(() =>
  checkIsLoadingTextBtn.value ? props.loadingText : props.label,
);

const isLink = Boolean(props.to || props.href);

const buttonComponent = computed(() => {
  if (!isLink) {
    return {
      component: "button",
      bind: {},
    };
  }

  const target =
    props.targetBlank ||
    isExternalLink(props.href || props.to || "") ||
    getNewTabEnabled
      ? "_blank"
      : "_self";

  if (props.href) {
    return {
      component: "a",
      bind: { href: props.href, target },
    };
  }

  return {
    component: "router-link",
    bind: { to: props.to, target },
  };
});

//TODO: remove this after completing update everywhere
const iconSrc = computed(() =>
  isHovered.value && !props.isDisabled && props.iconHover
    ? props.iconHover
    : props.icon,
);

const trailingIconSrc = computed(() =>
  isHovered.value && !props.isDisabled && props.trailingIconHover
    ? props.trailingIconHover
    : props.trailingIcon,
);

const isIconImported = (icon: string) =>
  icon?.startsWith("data:image/png") ||
  icon?.startsWith("/img/") ||
  icon?.includes("http");

const setIsHover = (value: boolean) => {
  isHovered.value = value;
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(8px);
  border: none;
  border-radius: rem(8px);
  font-family: inherit;
  letter-spacing: rem(0.5px);
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
  padding: rem(2px);
  width: v-bind(width);
  height: v-bind(height);
  padding: v-bind(padding);

  &--icon-only {
    padding: 0;
    border-radius: 0;
    aspect-ratio: 1;
    width: auto;
    height: auto;
  }

  &__label {
    white-space: nowrap;
    color: currentColor;
    font-size: v-bind(fontSize);
    font-weight: v-bind(fontWeight);
  }

  &__icon {
    flex-shrink: 0;
    transition: transform 0.2s ease-out;
  }

  &__trailing-icon {
    transform: translateX(rem(4px));
  }

  &:not(&--disabled) {
    cursor: pointer;
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:hover:not(:disabled) {
      background-color: darken($primary, 10%);
    }
  }

  &--bordered {
    background-color: $white;
    border: rem(1px) solid $border;
    color: $primary;

    &:hover:not(:disabled) {
      border-color: $primary;
      background-color: $white;
    }
  }

  &--danger {
    background-color: $danger;
    color: $white;

    &:hover:not(:disabled) {
      background-color: lighten($danger, 10%);
    }
  }

  &--empty {
    background-color: transparent;
    color: $primary;

    &:hover:not(:disabled) {
      background-color: rgba($primary, 0.05);
    }
  }

  &--disabled {
    background-color: $disabled;
    color: rgba($white, 0.8);
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import type { AppFloatingMenuOption } from "@/shared/types/components";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import { computed } from "vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFloatingMenuItem',
  props: {
    item: {}
  },
  setup(__props: any) {



const iconColor = computed(() => {
  if (__props.item.iconColor) {
    return { color: __props.item.iconColor };
  }
  return {};
});

const labelClasses = computed(() => [
  "app-floating-menu-item__label",
  { "app-floating-menu-item__label--selected": __props.item.isSelected },
  { "app-floating-menu-item__label--disabled": __props.item.isDisabled },
]);

const linkProp = computed(() => {
  if (__props.item.isExternalLink) {
    return { href: __props.item.link, "link-component": "a" };
  }
  return { to: __props.item.link, "link-component": "router-link" };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    role: "menuitem",
    class: _normalizeClass([
      'app-floating-menu-item',
      { 'app-floating-menu-item--selected': _ctx.item.isSelected },
      { 'app-floating-menu-item--disabled': _ctx.item.isDisabled },
    ])
  }, [
    (_ctx.item.link)
      ? (_openBlock(), _createBlock(AppLink, _mergeProps({ key: 0 }, linkProp.value, { class: labelClasses.value }), {
          default: _withCtx(() => [
            (_ctx.item.iconSrc)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  style: _normalizeStyle(iconColor.value),
                  icon: _ctx.item.iconSrc,
                  size: "24px",
                  class: "app-floating-menu-item__icon"
                }, null, 8, ["style", "icon"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.item.label), 1)
          ]),
          _: 1
        }, 16, ["class"]))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(labelClasses.value)
        }, [
          (_ctx.item.iconSrc)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                size: "22px",
                icon: _ctx.item.iconSrc,
                class: "app-floating-menu-item__icon"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.item.label), 1)
        ], 2))
  ], 2))
}
}

})
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-title" }
const _hoisted_2 = { class: "app-free-trial-modal__message" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppModal from "@/components/app/AppModal/AppModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFreeTrialModal',
  props: /*@__PURE__*/_mergeModels({
    title: {},
    message: {}
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const isOpen = _useModel<boolean>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    class: "app-free-trial-modal",
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
    size: "small"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1)
    ]),
    footer: _withCtx(({ hideModal }) => [
      _createVNode(AppButton, {
        label: "Close",
        type: "empty",
        onClick: hideModal
      }, null, 8, ["onClick"]),
      _createVNode(AppButton, {
        href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
        label: "Upgrade now"
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
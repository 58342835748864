import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }

import { ref, useTemplateRef, watch } from "vue";
import { useRoute } from "vue-router";
import AppHeader from "@/components/app/AppHeader/AppHeader.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";
import AppSidebar from "@/components/app/AppSidebar/AppSidebar.vue";
import { useNavigation } from "@/shared/composables/useNavigation/useNavigation";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLayout',
  setup(__props) {

const route = useRoute();
const webCustomerStore = useWebCustomerStore();
const { showSubscriptionBanner } = webCustomerStore;
const { navigationLinks } = useNavigation();

const appContent = useTemplateRef<HTMLDivElement>("appContent");
const bookCallModal = ref(false);

const handleOpenBookCallModal = () => {
  bookCallModal.value = true;
};

watch(
  () => route,
  () => {
    if (appContent.value) {
      appContent.value.scrollTop = 0;
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(showSubscriptionBanner))
        ? (_openBlock(), _createBlock(AppExpiryBanner, {
            key: 0,
            onOpenBookCallModal: handleOpenBookCallModal
          }))
        : _createCommentVNode("", true),
      _createVNode(AppHeader, {
        class: _normalizeClass({ 'app-layout__header-with-banner': _unref(showSubscriptionBanner) })
      }, null, 8, ["class"]),
      _createElementVNode("main", {
        class: _normalizeClass({
        'app-layout__body': true,
        'app-layout__header-with-banner': _unref(showSubscriptionBanner),
      })
      }, [
        _createVNode(AppSidebar, {
          links: _unref(navigationLinks),
          class: _normalizeClass({
          'app-layout__header-with-banner-top': _unref(showSubscriptionBanner),
        })
        }, null, 8, ["links", "class"]),
        _createElementVNode("div", {
          class: "app-layout__body__content",
          ref_key: "appContent",
          ref: appContent
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 512)
      ], 2)
    ]),
    _createVNode(BookCallModal, {
      modelValue: bookCallModal.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((bookCallModal).value = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}
}

})
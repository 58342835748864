import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-controls", "aria-expanded"]


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAvatar',
  props: /*@__PURE__*/_mergeModels({
    initials: {}
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const isActive = _useModel<boolean>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (isActive.value = !isActive.value)),
    "aria-controls": isActive.value ? 'user-menu' : undefined,
    "aria-expanded": isActive.value,
    "aria-haspopup": "menu",
    class: _normalizeClass(['user-avatar', { 'user-avatar--active': isActive.value }])
  }, _toDisplayString(_ctx.initials), 11, _hoisted_1))
}
}

})
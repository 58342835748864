import axios from "@/plugins/axios/axios";
import { AxiosResponse } from "axios";
import {
  AffinityList,
  AffinitySyncField,
  FetchFilteredPortfoliosBody,
  PushCompanyToAffinity,
  PushableColumn,
  TBInvestorPortfolio,
  TBPortfolio,
  WCInvestorPortfolio,
  WCPortfolio,
  PortfolioFilterResponse,
  PortfolioResponse,
  SalesforceField,
} from "@/shared/types/portfolios";
import { MainFilter } from "@/shared/types/filters";
import { TeamMember } from "@/shared/types/profileClaim";
import { AffinityLink, TeamMemberShareStatus } from "@/shared/types/portfolios";

function getFilteredWebCustomerPortfolios(
  fetchFilteredBody: FetchFilteredPortfoliosBody,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<PortfolioFilterResponse<TBPortfolio>>> {
  return axios.post("/portfolio/filter", fetchFilteredBody, {
    signal: abortSignal,
  });
}

function getFilteredInvestorPortfolios(
  fetchFilteredBody: FetchFilteredPortfoliosBody,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<PortfolioFilterResponse<TBInvestorPortfolio>>> {
  return axios.post("/investorPortfolio/filter", fetchFilteredBody, {
    signal: abortSignal,
  });
}

function createTbPortfolio(
  portfolioName: string,
): Promise<AxiosResponse<TBPortfolio>> {
  return axios.post("/portfolio", { portfolioName });
}

function deleteTbPortfolio(portfolioId: number) {
  return axios.delete(`/portfolio/${portfolioId}`);
}

function renameTbPortfolio(
  portfolioID: number,
  portfolioName: string,
): Promise<AxiosResponse<TBPortfolio>> {
  return axios.put("/portfolio", {
    portfolioID,
    portfolioName,
  });
}

function removeCompanyFromPortfolio(portfolioId: number, companyId: number) {
  return axios.delete(`/portfolio/${portfolioId}/${companyId}`);
}

function addCompaniesToPortfolios(clientIDs: number[], portfolioIDs: number[]) {
  return axios.post("/portfolio/addCompanies", {
    portfolioIDs,
    clientIDs,
  });
}

function addCompanyToPortfolio(clientID: number, portfolioID: number) {
  return axios.post(`/portfolio/${portfolioID}`, clientID);
}

function getWebCustomerWcPortfolios(
  clientIDs: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<PortfolioResponse<WCPortfolio>>> {
  return axios.post("/portfolio/findWCPortfolioCompanies", clientIDs, {
    signal: abortSignal,
  });
}

function bulkAddCompaniesToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(`/portfolio/bulk-add/${portfolioId}`, mainFilter);
}

function bulkAddFundingRoundsCompaniesToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(
    `/portfolio/bulk-add-funding-rounds/${portfolioId}`,
    mainFilter,
  );
}

function syncAffinityList(
  portfolioID: number,
): Promise<AxiosResponse<boolean>> {
  return axios.get(`/affinity/updateList/${portfolioID}`);
}

function getAffinityLists(): Promise<AxiosResponse<AffinityList[]>> {
  return axios.get("/affinity/lists");
}

function createInvestorPortfolio(
  portfolioName: string,
): Promise<AxiosResponse<TBInvestorPortfolio>> {
  return axios.post("/investorPortfolio", { portfolioName });
}

function deleteInvestorPortfolio(portfolioId: number) {
  return axios.delete(`/investorPortfolio/${portfolioId}`);
}

function getWCInvestorPortfolios(
  investorIDs: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<PortfolioResponse<WCInvestorPortfolio>>> {
  return axios.post(
    "/investorPortfolio/findWCPortfolioInvestors",
    investorIDs,
    {
      signal: abortSignal,
    },
  );
}

function addInvestorsToInvestorPortfolio(
  investorIds: number[],
  portfolioId: number,
): Promise<AxiosResponse<number[]>> {
  return axios.post(`/investorPortfolio/${portfolioId}`, investorIds);
}

function addInvestorsToPortfolios(
  investorIDs: number[],
  portfolioIDs: number[],
) {
  return axios.post("/investorPortfolio/addInvestors", {
    investorIDs,
    portfolioIDs,
  });
}

function bulkAddInvestorsToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(`/investorPortfolio/bulk-add/${portfolioId}`, mainFilter);
}

function getAffinityLink(id: number): Promise<AxiosResponse<AffinityLink>> {
  return axios.get(`/affinity/getLink/${id}`);
}

function getAffinityFields(
  affinityListID?: number,
): Promise<AxiosResponse<AffinitySyncField[]>> {
  return axios.get(
    `/affinity/fields${affinityListID ? `/${affinityListID}` : ""}`,
  );
}

function connectAffinityList(
  affinityLink: AffinityLink,
): Promise<AxiosResponse<AffinityLink>> {
  return axios.post("/affinity/linkAndSync", affinityLink);
}

function pushAffinityList(
  affinityLink: AffinityLink,
): Promise<AxiosResponse<boolean>> {
  return axios.post("/affinity/push", affinityLink);
}

function getAffinityColumnMapping(
  id: number,
): Promise<AxiosResponse<PushableColumn[]>> {
  return axios.get(`/affinity/defaultMapping/${id}`);
}

function pushSingleCompany(
  affinityLink: PushCompanyToAffinity,
): Promise<AxiosResponse<boolean>> {
  return axios.post("/affinity/pushSingleCompany", affinityLink);
}

function getSalesforceFields(): Promise<AxiosResponse<SalesforceField[]>> {
  return axios.get("/salesforce/fields");
}

function pushSingleCompanyToSalesforce(payload: {
  clientID: number;
  fieldNames: string[];
  autoSync: boolean;
  frequency: string;
}): Promise<AxiosResponse<boolean>> {
  return axios.post("/salesforce/push", payload);
}

function pushPortfolioToSalesforce(payload: {
  portfolioID: number;
  fieldNames: string[];
  autoSync: boolean;
  frequency: string;
}): Promise<AxiosResponse<boolean>> {
  return axios.post("/salesforce/push/portfolio", payload);
}

function pushSavedSearchToSalesforce(payload: {
  savedSearchID: number;
  fieldNames: string[];
  autoSync: boolean;
  frequency: string;
}): Promise<AxiosResponse<boolean>> {
  return axios.post("/salesforce/push/savedSearch", payload);
}

function getTeamMembersBySearch(
  param: string,
): Promise<AxiosResponse<TeamMember[]>> {
  return axios.get(`/teams/teamMembers/search/${param}`);
}

function updateInvestorPortfolioShareStatusByListId(
  listId: number,
  memberSharingModes: TeamMemberShareStatus,
): Promise<AxiosResponse<boolean>> {
  return axios.put(`/investorPortfolio/share/${listId}`, {
    memberSharingModes,
  });
}

function updatePortfolioShareStatusByListId(
  listId: number,
  memberSharingModes: TeamMemberShareStatus,
): Promise<AxiosResponse<boolean>> {
  return axios.put(`/portfolio/share/${listId}`, { memberSharingModes });
}

function getInvestorPortfolioSharedUserByListId(
  listId: number,
): Promise<AxiosResponse<TeamMember[]>> {
  return axios.get(`/investorPortfolio/share/${listId}`);
}

function getPortfolioSharedUserByListId(
  listId: number,
): Promise<AxiosResponse<TeamMember[]>> {
  return axios.get(`/portfolio/share/${listId}`);
}

function getInvestorPortfolioOwnerByListId(
  listId: number,
): Promise<AxiosResponse<TeamMember>> {
  return axios.get(`/investorPortfolio/owner/${listId}`);
}

function getPortfolioOwnerByListId(
  listId: number,
): Promise<AxiosResponse<TeamMember>> {
  return axios.get(`/portfolio/owner/${listId}`);
}

export default {
  getFilteredWebCustomerPortfolios,
  getFilteredInvestorPortfolios,
  createTbPortfolio,
  deleteTbPortfolio,
  removeCompanyFromPortfolio,
  addCompaniesToPortfolios,
  getWebCustomerWcPortfolios,
  bulkAddCompaniesToPortfolios,
  bulkAddFundingRoundsCompaniesToPortfolios,
  renameTbPortfolio,
  syncAffinityList,
  getAffinityLists,
  createInvestorPortfolio,
  deleteInvestorPortfolio,
  getWCInvestorPortfolios,
  addInvestorsToInvestorPortfolio,
  bulkAddInvestorsToPortfolios,
  addCompanyToPortfolio,
  addInvestorsToPortfolios,
  getAffinityFields,
  connectAffinityList,
  pushAffinityList,
  getAffinityLink,
  pushSingleCompany,
  getAffinityColumnMapping,
  getTeamMembersBySearch,
  updatePortfolioShareStatusByListId,
  updateInvestorPortfolioShareStatusByListId,
  getPortfolioSharedUserByListId,
  getInvestorPortfolioSharedUserByListId,
  getSalesforceFields,
  pushSingleCompanyToSalesforce,
  pushPortfolioToSalesforce,
  pushSavedSearchToSalesforce,
  getInvestorPortfolioOwnerByListId,
  getPortfolioOwnerByListId,
};

import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { Ref } from "vue";

interface UseSearchNavigationParams {
  searchText: Ref<string>;
}

export const useSearchNavigation = ({
  searchText,
}: UseSearchNavigationParams) => {
  const MIN_SEARCH_LENGTH = 2;

  const route = useRoute();
  const router = useRouter();

  const navigateToSearch = () => {
    if (
      route.name !== "SearchResultsView" &&
      searchText.value.length > MIN_SEARCH_LENGTH
    ) {
      router.push({
        name: "SearchResultsView",
        params: {
          tab: "all",
          keyword: searchText.value,
        },
      });
    }
  };

  watch(searchText, navigateToSearch);

  return {
    navigateToSearch,
  };
};

import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import { ADMIN_PANEL_TABS } from "@/shared/types/admin";
import { AppSidebarLink } from "@/shared/types/components";

export function useNavigation() {
  const route = useRoute();
  const router = useRouter();
  const webCustomerStore = useWebCustomerStore();
  const { searchResultsText } = useSearchResults();
  const { webCustomer } = webCustomerStore;

  const navigationLinks = computed((): AppSidebarLink[] => {
    return [
      {
        icon: "dashboard",
        name: "Dashboard",
        path: "/dashboard",
      },
      {
        icon: "search",
        name: "Search",
        path: "/search/",
        children: [
          {
            name: "Recently raised",
            path: "/search/recently-raised",
          },
          {
            name: "Recently viewed",
            path: "/search/recently-viewed",
          },
          {
            name: "Top viewed",
            path: "/search/top-viewed",
          },
          {
            name: "Recent deals",
            path: "/search/recent-deals",
          },
          {
            name: "Largest deals",
            path: "/search/largest-deals",
          },
          {
            name: "Exits",
            path: "/search/exit-deals",
          },
        ],
      },
      {
        icon: "taxonomy",
        name: "Taxonomy",
        path: "/taxonomy",
      },
      {
        icon: "document",
        name: "Market Research",
        path: "/market-research",
      },
      {
        icon: "heart",
        name: "Lists",
        path: "/lists",
        children: [
          {
            name: "Organisations",
            path: "/lists/organisations",
          },
          {
            name: "Investors",
            path: "/lists/investors",
          },
        ],
      },
      {
        icon: "bookmark",
        name: "My searches",
        path: "/searches",
      },
      ...(webCustomer.freelanceStackRewardCoupon
        ? [
            {
              icon: "medal",
              name: "Rewards & discounts",
              path: webCustomer.freelanceStackRewardCoupon,
              isExternal: true,
            },
          ]
        : []),
      ...(webCustomerStore.isUserAdmin
        ? [
            {
              icon: "admin",
              name: "Admin panel",
              path: "/admin-panel",
              children: [
                {
                  name: "Startups Submission",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.STARTUP_SUBMISSION}`,
                },
                {
                  name: "Taxonomy Tags",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TAGS}`,
                },
                {
                  name: "Taxonomy Tags Types",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TYPES}`,
                },
                {
                  name: "Taxonomy Tags Families",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_FAMILIES}`,
                },
                {
                  name: "Taxonomy Items",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_ITEMS}`,
                },
                {
                  name: "Taxonomy Relations",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_RELATIONS}`,
                },
                {
                  name: "Web Customers",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.WEB_CUSTOMERS}`,
                },
                {
                  name: "Market Reports",
                  path: `/admin-panel/${ADMIN_PANEL_TABS.MARKET_REPORT}`,
                },
              ],
            },
          ]
        : []),
    ];
  });

  const goSearchResultsPage = () => {
    if (
      route.name !== "SearchResultsView" &&
      searchResultsText.value.length > 2
    ) {
      router.push({
        name: "SearchResultsView",
        params: {
          tab: "all",
          keyword: searchResultsText.value,
        },
      });
    }
  };

  return {
    navigationLinks,
    goSearchResultsPage,
  };
}
